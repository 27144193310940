// JS-Funktionen in einzelnen Datein unter "components":
import { consoleMessage } from './components/console-message';
import { externalLinks } from './components/external-links';
import { generalFunctions } from './components/general-functions';
// import { germanChosen } from './components/german-chosen';
// import { choicesSelect } from './components/choices-select';
import { htmxFunctions } from './components/htmx';
import { iconsSprite } from './components/icons-sprite';
import { popoverLists } from './components/bs-popover-lists'; // jQuery B4 Popovers
import { toastsList } from './components/bs-toasts-list'; // jQuery B4 Toasts
import { tooltipLists } from './components/bs-tooltip-lists'; // jQuery B4 Tooltips

generalFunctions();
iconsSprite();

document.addEventListener('DOMContentLoaded', () => {
  /* eslint-disable no-new, no-restricted-syntax */
  consoleMessage();
  externalLinks();
  // germanChosen();
  popoverLists();
  toastsList();
  tooltipLists();
});

// re-init after HTMX swap
document.addEventListener('htmx:afterSwap', (evt) => {
  // germanChosen(evt);
  htmxFunctions(evt);
  popoverLists(evt);
  toastsList(evt);
  tooltipLists(evt);
});
