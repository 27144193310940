export function iconsSprite() {
  const request = new XMLHttpRequest();
  request.open('GET', '/static/img/icons.svg', true);
  request.send();
  request.onload = (e) => {
    const div = document.createElement('div');
    div.innerHTML = request.responseText;
    document.body.append(div, document.body.childNodes[0]);
  };
}
