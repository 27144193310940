// Initialize Bootstrap tooltips
export function tooltipLists() {
  // Vanilla:
  // const tooltipTriggerList = document.querySelectorAll('[data-toggle="tooltip"]')
  // const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
  // jQuery:
  $('[data-toggle="tooltip"]').tooltip({
    container: '.wrapper',
  });
}
