export function generalFunctions() {
  document.documentElement.classList.remove('no-js');
  document.documentElement.classList.add('js');

  // Find disabled links
  var disabledLinks = document.querySelectorAll('.disabled');
  // Add a click event listener to each one
  disabledLinks.forEach((link) => {
    link.addEventListener('click', (evt) => {
      evt.preventDefault();
    });
  });
}
